import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import { ReactElement, useEffect, useRef } from "react"
import { useResizeDetector } from "react-resize-detector"
import Scrollbar from "smooth-scrollbar"
import useWindowSize from "../hooks/useWindowSize"
import { breakpointTypes } from "../utils/types"
gsap.registerPlugin(ScrollTrigger)

interface Props {
  children: ReactElement
  location: any
}

const WithSmooth = ({ children, location }: Props) => {
  const scrollerRef = useRef<HTMLDivElement>(null)
  const selectorRef = useRef<HTMLDivElement>(null)
  // const [isTouchscreen, setIsTouchscreen] = useState(false)
  const q = gsap.utils.selector(selectorRef)
  const smooth = useRef<any>(null)
  const windowSize = useWindowSize()

  const onResize = () => {
    ScrollTrigger.refresh()
  }

  useResizeDetector({ targetRef: selectorRef, onResize })

  // ANIMATIONS
  const initAnimations = (smoothInstance: Scrollbar | null) => {
    // MARQUEE

    // let currentScroll = 0
    // let isScrollingDown = true

    // console.log(q("[data-marquee]"))

    gsap.set(q("[data-marquee]"), { xPercent: -100 })
    // const marqueeTL = gsap.to(q("[data-marquee-sliding-inner]"), {
    //   xPercent: -100,
    //   repeat: -1,
    //   duration: 10,
    //   ease: "linear",
    // })

    // const handleDirChange = (offset: any) => {
    //   isScrollingDown = offset.y || offset > currentScroll ? true : false

    //   gsap.to(marqueeTL, {
    //     timeScale: isScrollingDown ? -1 : 1,
    //   })

    //   currentScroll = offset.y || offset
    // }

    // let currentScroll = 0
    // let isScrollingDown = true

    // gsap.set(q("[data-marquee-sliding]"), { xPercent: -100 })

    // const marqueeTL = gsap
    //   .to(q("[data-marquee-text]"), {
    //     xPercent: -100,
    //     repeat: -1,
    //     duration: 2,
    //     ease: "linear",
    //   })
    //   .totalProgress(0.5)

    // const handleDirChange = (offset: any) => {
    //   isScrollingDown = offset.y || offset > currentScroll ? true : false

    //   gsap.to(marqueeTL, {
    //     timeScale: isScrollingDown ? -1 : 1,
    //   })

    //   currentScroll = offset.y || offset
    // }

    /* ADD SKEW SECTION */
    let proxy = { skew: 0, scale: 0 }
    let skewSetter = gsap.quickSetter("[data-marquee-sliding]", "skewX", "deg") // fast
    let clamp = gsap.utils.clamp(-20, 20) // don't let the skew go beyond 20 degrees.

    ScrollTrigger.create({
      onUpdate: (self) => {
        let skew = clamp(self.getVelocity() / -200)

        // only do something if the skew is MORE severe. Remember, we're always tweening back to 0, so if the user slows their scrolling quickly, it's more natural to just let the tween handle that smoothly rather than jumping to the smaller skew.
        if (Math.abs(skew) > Math.abs(proxy.skew)) {
          proxy.skew = skew
          gsap.to(proxy, {
            // xPercent: () => 100 * self.direction,
            skew: 0,
            duration: 0.4,
            ease: "power3",
            overwrite: true,
            onUpdate: () => {
              skewSetter(proxy.skew)
              // scaleSetter(proxy.scale)
            },
          })
        }
      },
    })

    gsap.to(q("[data-marquee-sliding]"), {
      xPercent: 10,
      scrollTrigger: {
        trigger: q("[data-marquee-sliding]"),
        // markers: true,
        scrub: 1,
      },
    })

    // if (smoothInstance) {
    //   smoothInstance?.addListener(({ offset, limit }: any) => {
    //     let mapped = gsap.utils.mapRange(
    //       0,
    //       limit.y,
    //       -windowSize.width,
    //       windowSize.width,
    //       offset.y
    //     )

    //     if (offset) {
    //       gsap.to(q("[data-marquee-sliding]"), {
    //         x: mapped + "px",
    //         ease: "linear",
    //       })
    //     }
    //   })
    // } else {
    //   document.addEventListener("scroll", () => {
    //     const limit = Math.max(
    //       document.body.scrollHeight,
    //       document.body.offsetHeight,
    //       document.documentElement.clientHeight,
    //       document.documentElement.scrollHeight,
    //       document.documentElement.offsetHeight
    //     )

    //     let mapped = gsap.utils.mapRange(
    //       0,
    //       limit,
    //       -windowSize.width,
    //       windowSize.width,
    //       window.pageYOffset
    //     )

    //     gsap.to(q("[data-marquee-sliding]"), {
    //       x: mapped + "px",
    //       ease: "power1",
    //     })
    //   })
    // }

    // console.log("anims")

    if (windowSize.width >= breakpointTypes.tablet) {
      // PARALLAX ITEMS
      const parallaxItems: any = q("[data-parallax]")

      parallaxItems.forEach((item: any) => {
        gsap.set(item, {
          yPercent: 100 * (item.dataset.speed ? item.dataset.speed : 0.4),
        })

        gsap.to(item, {
          yPercent: () =>
            -100 *
            (item.dataset.speed ? item.dataset.speed : 0.4) *
            (item.dataset.direction ? item.dataset.direction : 1),
          scrollTrigger: {
            trigger: item,
            scrub: true,
          },
        })
      })

      // SCALING IMG
      const itemWrappers = q("[data-scaling-img-wr]")
      const items = q("[data-scaling-img]")

      gsap.set(q("[data-scaling-img]"), {
        scale: 1.2,
      })

      itemWrappers.forEach((item, i) => {
        gsap.to(items[i], {
          scale: 1,
          scrollTrigger: {
            trigger: item,
            scrub: true,
            // markers: true,
          },
        })
      })
    }

    // ACCORDION
    const accordionItems = q("[data-accordion-item]")

    accordionItems.forEach((item) => {
      item.addEventListener("click", (e: any) => {
        smoothInstance?.scrollIntoView(e.currentTarget)
      })
    })
  }

  // useEffect(() => {
  //   if (windowSize.width <= breakpointTypes.tablet) {
  //     setIsTouchscreen(true)
  //   } else {
  //     setIsTouchscreen(false)
  //   }
  // }, [windowSize.width])

  useEffect(() => {
    if (windowSize.width <= breakpointTypes.tablet) {
      initAnimations(null)
      console.log("lol", scrollerRef.current)

      return
    }

    // Init smooth scroll
    const scroller = scrollerRef.current

    if (!scroller) {
      initAnimations(null)
      return
    }

    if (smooth.current) {
      smooth.current?.destroy()
      console.log("Smooth Destroyed")
    }

    smooth.current = Scrollbar.init(scroller, {
      damping: 0.075,
      delegateTo: document,
      alwaysShowTracks: true,
      renderByPixels: false,
    })

    console.log("Smooth Initialized")

    ScrollTrigger.scrollerProxy(scrollerRef.current, {
      scrollTop(value) {
        if (arguments.length) {
          smooth.current.scrollTop = value
        }
        return smooth.current.scrollTop
      },
    })

    smooth.current?.addListener(ScrollTrigger.update)
    ScrollTrigger.defaults({ scroller })

    initAnimations(smooth.current)

    // Only necessary to correct marker position - not needed in production
    if (document.querySelector(".gsap-marker-scroller-start")) {
      const markers = gsap.utils.toArray('[class *= "gsap-marker"]')

      smooth.current?.addListener(({ offset }: any) => {
        gsap.set(markers, { marginTop: -offset.y })
      })
    }

    const clearAnims = () => {
      ScrollTrigger.getAll().forEach((instance) => {
        instance.kill()
      })

      // This in case a scroll animation is active while the route is updated
      gsap.killTweensOf(window)
    }

    return () => {
      clearAnims()
      // smooth.current?.destroy()
      // console.log("Smooth Destroyed")
    }
  }, [location, windowSize.width])

  return (
    <div ref={selectorRef}>
      {windowSize.width >= breakpointTypes.tablet ? (
        <div data-scroller ref={scrollerRef}>
          {children}
        </div>
      ) : (
        <>{children}</>
      )}
    </div>
  )
}

export { WithSmooth }
