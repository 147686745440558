import s from "assets/scss/BlogDetail.module.scss"
import { useEffect, useRef, useState } from "react"
import { Link, useParams } from "react-router-dom"

import "assets/scss/slickReset.scss"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"

import Slider from "react-slick"

import { IBlog } from "models/IBlog"
import BlogCard from "./BlogCard"
import IconArrowSlider from "./Icon/IconArrowSlider"

import axios from "axios"
import { useTranslation } from "react-i18next"
import { api } from "utils/types"
import Footer from "./Footer"
import Img from "./Img"

const BlogDetail = () => {
  const sliderRef = useRef<Slider | null>(null)
  const [selected, setSelected] = useState(0)
  const { i18n } = useTranslation()
  const params = useParams()
  const [data, setData] = useState<IBlog | null>(null)

  // const blogs = [
  //   {
  //     title: "YENİ İÇERİKLERİ YAKALAMAK İÇİN",
  //     summary:
  //       "En son yayınlanan fikirler, tartışmalar ve makaleler hakkında düzenli güncellemeler almak için bültenimize abone olun.",
  //     html: "<p> En son yayınlanan fikirler, tartışmalar ve makaleler hakkında düzenli güncellemeler almak için bültenimize abone olun.</p>",
  //     id: "0",
  //     image: smp,
  //   },
  //   {
  //     title: "YENİ İÇERİKLERİ YAKALAMAK İÇİN",
  //     summary:
  //       "En son yayınlanan fikirler, tartışmalar ve makaleler hakkında düzenli güncellemeler almak için bültenimize abone olun.",
  //     html: "<p> En son yayınlanan fikirler, tartışmalar ve makaleler hakkında düzenli güncellemeler almak için bültenimize abone olun.</p>",
  //     id: "1",
  //     image: smp,
  //   },
  //   {
  //     title: "YENİ İÇERİKLERİ YAKALAMAK İÇİN",
  //     summary:
  //       "En son yayınlanan fikirler, tartışmalar ve makaleler hakkında düzenli güncellemeler almak için bültenimize abone olun.",
  //     html: "<p> En son yayınlanan fikirler, tartışmalar ve makaleler hakkında düzenli güncellemeler almak için bültenimize abone olun.</p>",
  //     id: "2",
  //     image: smp,
  //   },
  //   {
  //     title: "YENİ İÇERİKLERİ YAKALAMAK İÇİN",
  //     summary:
  //       "En son yayınlanan fikirler, tartışmalar ve makaleler hakkında düzenli güncellemeler almak için bültenimize abone olun.",
  //     html: "<p> En son yayınlanan fikirler, tartışmalar ve makaleler hakkında düzenli güncellemeler almak için bültenimize abone olun.</p>",
  //     id: "3",
  //     image: smp,
  //   },
  //   {
  //     title: "YENİ İÇERİKLERİ YAKALAMAK İÇİN",
  //     summary:
  //       "En son yayınlanan fikirler, tartışmalar ve makaleler hakkında düzenli güncellemeler almak için bültenimize abone olun.",
  //     html: "<p> En son yayınlanan fikirler, tartışmalar ve makaleler hakkında düzenli güncellemeler almak için bültenimize abone olun.</p>",
  //     id: "4",
  //     image: smp,
  //   },
  // ]

  const settings = {
    variableWidth: true,
    infinite: false,
    speed: 800,
    slidesToScroll: 1,
    slidesToShow: 2.5,
    dots: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  useEffect(() => {
    axios
      .get(`${api}blog.php`, {
        params: {
          language: i18n.language,
          url: params.blogUrl,
        },
      })
      .then((res) => {
        const data: IBlog = res.data
        setData(data)

        console.log(data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [params])

  useEffect(() => {
    if (!data) return
    window.history.replaceState(
      null,
      `/${data?.langUrl}`,
      `/blog/${data?.langUrl}`
    )
  }, [i18n.language])

  useEffect(() => {
    sliderRef.current?.slickGoTo(selected)
  }, [selected])

  return (
    <>
      <main className={s.blogDetail}>
        <section className={s.content}>
          <Link to="/blog" className={s.goBack}>
            <div className={s.iconW}>
              <IconArrowSlider fill="#3b3b3b" rotate={180} scale={1.5} />
            </div>
            Geri Dön
          </Link>
          {data && data.image && (
            <div className={s.cover}>
              <Img src={data.image}></Img>
            </div>
          )}

          {data && (
            <div
              dangerouslySetInnerHTML={{ __html: data.content }}
              className={s.contentHtml}
            ></div>
          )}
        </section>

        <section className={s.other}>
          <h5 className={s.title}>Diğer Blog İçerikleri</h5>
          <div className={s.sliderW}>
            <Slider className={s.slider} {...settings} ref={sliderRef}>
              {Array.isArray(data?.otherBlogs) &&
                data?.otherBlogs.map((blog: any, i) => {
                  return (
                    <div className={s.sliderItem} key={i}>
                      <BlogCard blog={{ ...blog }}></BlogCard>
                    </div>
                  )
                })}
            </Slider>
          </div>
        </section>
      </main>
      <Footer />
    </>
  )
}

export default BlogDetail
