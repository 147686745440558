import { ReactElement } from "react"
import s from "../assets/scss/Marquee.module.scss"

type Props = {
  children: ReactElement
}

function Marquee(props: Props) {
  return (
    // <div className={s.marquee} data-marquee>
    //   <div className={s.marqueeInner} data-marquee-sliding>
    //     {Array.from(Array(10).keys()).map((i) => {
    //       return (
    //         <div className={s.marqueeItem} key={i}>
    //           {props.children}
    //         </div>
    //       )
    //     })}
    //   </div>
    // </div>
    <div className={s.marquee} data-marquee>
      <div className={s.marqueeInner} data-marquee-sliding>
        {Array.from(Array(10)).map((i) => {
          return (
            <div className={s.marqueeItem} key={i}>
              {props.children}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Marquee
